import React, { useEffect, useState } from "react";
import AxiosInstance from "src/components/Axios";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link, useLoaderData } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Message from "src/components/docs/Message";
import HistoryPower from "src/components/docs/HistoryPower";
import ActScore from "src/components/docs/ActScore";


const urlApi = '/accounts';
const urlApi_2 = '/agreements';
const urlRouter = '/accounts';


const role = localStorage.getItem('user_role');
const userData = JSON.parse(localStorage.getItem('user_data'));


export async function LoaderHome({ params }) {
    console.log(params)
    return params;
  }

const Home = ({ user_data }) => {

    const { key } = useLoaderData();
    const [rowsAcc, setRowsAcc] = useState(false);
    
    const [rowCountState, setRowCountState] = React.useState(0);

    const [activeKey, setActiveKey] = useState((key == undefined)? 'accounts': key);

    const exp = localStorage.getItem('user_exp');
    const dateNow = new Date();
    const dateSession = new Date(parseInt(exp) * 1000);

    if (dateNow >= dateSession) {
        return (<></>);
    }

    useEffect(() => {
       setActiveKey(key);
    }, [key]);

    const handleSelect = (key) => {
        setActiveKey(key);
        window.location.hash = key; // Оновлюємо URL при перемиканні вкладок
    };

    // Запит за контрагентами (+фільтр)
    const getRowsTable = (p = 1) => {
        if(userData == null)
            return;

        let request = ``;
        request += `&user.id=${userData.id}`;

        // console.log("request", request);
        AxiosInstance.get(`${urlApi}?page=${p}${request}`).then((response) => {
            setRowsAcc(response.data['hydra:member']);
            setRowCountState(response.data['hydra:totalItems']);
        });
    };


    useEffect(() => {
        getRowsTable()
    }, [])

    const activeTab = (t, r) => {
        setActiveKey(t);
    }


    return (<>
    <Tabs
    //   defaultActiveKey={activeKey}
      id="fill-tab-example"
      className="mb-3"
      activeKey={activeKey}
      fill
      onSelect={activeTab}
    >
      <Tab eventKey="accounts" title="Договори">
        <Card>
                <Card.Header as="h5">Контрагенти</Card.Header>
                <Card.Body className='row'>
                    {(rowsAcc.length == 0 )&& <p>Контрагентів не знайдено!</p>}
                    {(rowsAcc.length > 0 ) && rowsAcc.map((acc) => {
                        return(
                            <Link to={`/accounts/${acc.id}`} className='col-3' style={{textDecoration: 'none'}} key={`acc_${acc.id}`}>
                                <Card style={{ width: '18rem' }}>
                                    <Card.Body>
                                    <Card.Title>{acc.name}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{acc.edrpou}</Card.Subtitle>
                                    {/* <Card.Text>
                                        Some quick example text to build on the card title and make up the
                                        bulk of the card's content.
                                    </Card.Text> */}
                                    {/* <Card.Link href="#">Card Link</Card.Link>
                                    <Card.Link href="#">Another Link</Card.Link> */}
                                    </Card.Body>
                                </Card>
                            </Link>
                        )
                    })}
                </Card.Body>
        </Card>
      </Tab>
      <Tab eventKey="act" title="Акти Рахунки" >
        <Card>
            <Card.Header as="h5">Акти Рахунки</Card.Header>
            <Card.Body>
                {(userData != null) &&  <ActScore user_id={userData.id}/>}
            </Card.Body>
        </Card>
      </Tab>
      <Tab eventKey="message" title="Повідомлення">
        <Card>
            <Card.Header as="h5">Повідомлення</Card.Header>
            <Card.Body>
                {(userData != null) && <Message /> }
                
            </Card.Body>
        </Card>
      </Tab>
      <Tab eventKey="messege-agreement" title="Повідомлення за договором" >
        <Card>
            <Card.Header as="h5">Повідомлення за договором</Card.Header>
            <Card.Body>
                {(userData != null) &&  <Message user_id={userData.id}/>}   
            </Card.Body>
        </Card>
      </Tab>
      <Tab eventKey="history" title="Історія споживання" >
        <Card>
            <Card.Header as="h5">Історія споживання</Card.Header>
            <Card.Body>
                {(userData != null) &&  <HistoryPower user_id={userData.id}/>}
            </Card.Body>
        </Card>
      </Tab>
    </Tabs>
    
        

        
    </>)
}

export default Home;