import React, { useEffect, useRef, useState } from 'react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton, CRow, CCol, CCard, CCardHeader, CCardBody,
  CFormTextarea,
  CFormSelect
} from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import axios from "axios";
import Select from 'react-select'
import { DataGrid } from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {
  cilPen,
  cilPlus,
  cilTrash
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AxiosInstance from 'src/components/Axios';
import BasicModal from 'src/components/Modal';
import MediaFrom from '../media/MediaFrom';
import AddHeaderSlider from '../headers/AddHeaderSlider';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import { transliterate } from 'transliteration';
import { unique } from 'src/REDUX/store';
import AsyncSelect from 'react-select/async';




const DocsForm = ({ id,  getItems,account_id, agreement_id, actionShowModal, data, toastifySuccess, type }) => {

  console.log('data', data);
  const [media, setMedia] = useState();
  const [values, setValues] = useState({});
  const [mediaUrl, setMediaUrl] = useState(false);
  const [infoData, setInfoData] = useState([{
    key: '',
    value: ''
  }]);
  const popup = MyToastify();

  useEffect(() => {
    if (data != undefined) {
      // console.log(data);
      setValues({
        name: data.name,
        media: `/api/media_objects/${data.media.id}`,
        type: data.type,
        // account: '',
        // agreement: '',
        // dateEntered: '',
        // downloadType: 'cabinet',
        description: data.description,
      });
      setInfoData(data.data);
    }else{
      setValues({
        name: '',
        media: ``,
        type: 'акт',
        account: (account_id != undefined)? `/api/accounts/${account_id}`: null,
        agreement: (agreement_id != undefined) ? `/api/agreements/${agreement_id}`: null,
        // dateEntered: '',
        downloadType: 'cabinet',
        description: '',
      });
    }

  }, [data])

  useEffect(() => {
    console.log(media);
    if (media != undefined) {
      setValues({
        ...values,
        media: `/api/media_objects/${media}`
      });

      const response = AxiosInstance.get(`/media_objects/${media}`).then((response) => {
        if (response.status == 200) {
          setMediaUrl(response.data)
        }
      });
    }
  }, [media]);
  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    setValues({
      ...values,
      [name]: type === "number" ? parseInt(value, 10) : value
    });
  };

  /** Відправка форми */
  const handleSubmit = event => {
    event.preventDefault();

    if(values.media == ''){
      popup.toastifyWarning('Ви не загрузили документ');
      return;
    }
    if (data == undefined) {
      const response = AxiosInstance.post('/documents', values).then((response) => {
        if (response.status == 201) {
          actionShowModal(false);
          popup.toastifySuccess('Дані збережено');
          // navigate(`/menu/update/${response.data.id}`);
        }
      });
    } else {
      

      const response = AxiosInstance.put('/documents/' + data.id, values).then((response) => {
        if (response.status == 200) {
          actionShowModal(false);
          popup.toastifySuccess('Дані збережено');
        }
        // if(response.status == 201){
        //   navigate(`/menu/update/${response.data.id}`);
        // }
      });
    }
    // const response = AxiosInstance.put('/menus/' + data.id, values);

    // }

  }



  const handleChangeType = (v) => {
      console.log(v.target.value);
  }

  /** Контрагенти */
  const [selectedOption, setSelectedOption] = useState(null);
  const loadOptionsAcc = async (inputValue) => {
      return await AxiosInstance.get('/accounts?name=' + inputValue, { headers: { 'accept': 'application/json' } }).then((response) => {
          return response.data.map((result) => ({
              label: result.name,
              value: result.id,
          }));
      });
  }; 
  const loadOptionsAg = async (inputValue) => {
    let params = {
      number: inputValue
    };
    if(values.account.id != undefined)
        params['account.id'] = values.account.id;

      return await AxiosInstance.get('/agreements' , { params:params,  headers: { 'accept': 'application/json' } }).then((response) => {
          return response.data.map((result) => ({
              label: result.number,
              value: result.id,
          }));
      });
  }; 
  const handleChange = (value, name) => {
    setValues({
    ...values,
    [name]: `/api/${name}s/${value.value}`
    });
    // setSelectItems({
    // ...selectItems,
    // [name]: value
    // });
  };

  return (
    <>
      <MediaFrom type="category"  setNewMediaId={setMedia} toastifySuccess={toastifySuccess} />
      <p>
        {(data == undefined && mediaUrl && mediaUrl.contentUrl != undefined) && process.env.REACT_APP_SERVER_URL + mediaUrl.contentUrl}
        {(data != undefined && data.media.contentUrl != undefined) && process.env.REACT_APP_SERVER_URL + data.media.contentUrl}
      </p>
      <CForm onSubmit={handleSubmit} className="mt-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Тип</CFormLabel>


          <CFormSelect
            aria-label="Default select example"
            onChange={handleInputChange}
            defaultValue={(data != undefined) ?data.type: {}}
            name="type"
            options={[
              { label: 'акт', value: 'акт' },
              { label: 'рахунок', value: 'рахунок' },
              { label: 'скан договору', value: 'скан договору' },
              { label: 'додаткова угода', value: 'додаткова угода' },
              { label: 'список ЕІС', value: 'список ЕІС' },
              { label: 'повідомлення', value: 'повідомлення' },
              { label: 'повідомлення з ЕЦП', value: 'повідомлення з ЕЦП' },
              { label: 'динаміка обсягів споживання', value: 'динаміка обсягів споживання' },
              { label: 'інше', value: 'інше' },
            ]}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">коментар </CFormLabel>
          <CFormTextarea name="description"  defaultValue={values.description} onChange={handleInputChange} />
        </div>

        {(account_id == undefined) &&
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Контрагент</CFormLabel>
                <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptionsAcc}
                    className="flex-grow-1"
                    onChange={(value) =>{ handleChange(value, 'account')} }
                    
                />
          </div>
        
        }
        {(agreement_id == undefined) &&
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Контрагент</CFormLabel>
                <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptionsAg}
                    className="flex-grow-1"
                    onChange={(value) =>{ handleChange(value, 'account')} }   
                />
          </div>
        
        }
        

        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>
    </>
  );
}

export default DocsForm;