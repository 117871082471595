import { useEffect, useState } from "react";
import AxiosInstance from "../Axios";
import Table from 'react-bootstrap/Table';


const Message = ({account_id, agreement_id, user_id}) => {

    const [rowsMessage, setRowsMessage] = useState([]);


    // Запит за контрагентами (+фільтр)
    const getRowsMessage = (p = 1) => {
        let request = {'type': 'повідомлення'};
        if(account_id == undefined && agreement_id == undefined && user_id == undefined)
            request['exists[account]'] =  false;
        if(account_id != undefined)
            request['account.id'] = account_id;
        if(agreement_id != undefined)
            request['agreement.id'] = agreement_id;
        if(user_id != undefined)
            request['account.user.id'] = user_id;
        // console.log("request", request);
        AxiosInstance.get(`/documents?page=${p}`, {params: request}).then((response) => {
            setRowsMessage(response.data['hydra:member']);
        });
    };
    useEffect(() => {
        getRowsMessage()
    }, [])    
    return(
        <>
             <Table striped>
                <thead>
                    <tr>
                        <th>Тип</th>
                        <th>Документ</th>
                        <th>Опис</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rowsMessage.map((m) => {


                            return(
                                <tr key={`message_${m.id}`}>
                                    <th>{m.type}</th>
                                    <td><a href={process.env.REACT_APP_SERVER_URL + m.media.contentUrl}>Завантажити</a></td>
                                    <td>{m.description}</td>
                                </tr>
                            )
                        })
                    }
                    
                </tbody>
             </Table>
            
        </>
    );
}

export default Message;